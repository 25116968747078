(function () {
	'use strict';
	var detect = avastGlobals.detect,
			wrongDownlaodPopupClose = document.querySelector('.js-notification-overlay-for-wrong-download .js-close'),
			chooseDifferentPopup = document.querySelector('.js-notification-overlay-for-different-product'),
			runDetect = typeof enableFreegsrDetect !== 'undefined' ? enableFreegsrDetect : false,
			useExactIDs = typeof onlyExactIDs !== 'undefined' ? onlyExactIDs : false,
			downloadButtonIDs = typeof customDownloadIDs !== 'undefined' && customDownloadIDs.length ? customDownloadIDs : [
				'FREEGSR',
				'AVC',
				'AVF',
				'DEFAULT-www.avg.com',
				'GSU',
				'RCD',
				'XPROMO-AVG',
			], // IDs source: https://docs.google.com/spreadsheets/d/18YCaKnJeUWJGzMVw6IO9YY2wKXak9CcktUJcO797K5I/edit#gid=1052215900
			buttonSelector = '';

	if (runDetect && detect.os.isWindows && chooseDifferentPopup) {
		for (var i = 0; i < downloadButtonIDs.length; i++) {
			if (buttonSelector !== '') {
				buttonSelector += ', ';
			}
			if (useExactIDs) {
				buttonSelector += 'a[href*="product="][data-download-name="'+ downloadButtonIDs[i] +'"]';
				buttonSelector += ', a[href*="product="][data-download-name="'+ downloadButtonIDs[i].toLowerCase() +'"]';
			} else {
				buttonSelector += 'a[href*="product="][href*="'+ downloadButtonIDs[i] +'"]';
				buttonSelector += ', a[href*="product="][href*="'+ downloadButtonIDs[i].toLowerCase() +'"]';
			}
		}
		var downloadButtons = document.querySelectorAll(buttonSelector),
				chooseDifferentPopupClose = chooseDifferentPopup.querySelectorAll('.js-close');

		if (downloadButtons.length) {
			for (var i = 0; i < downloadButtons.length; i++) {
				downloadButtons[i].addEventListener('click', function(e) {
					e.preventDefault();

					chooseDifferentPopup.classList.add('show-popup');

					// make sure possible popup is hidden (if have time, find out cleaner way)
					if (wrongDownlaodPopupClose) {
						setTimeout(function() {
							wrongDownlaodPopupClose.click();
						}, 1)
					}
				})
			}
		}

		if (chooseDifferentPopupClose.length) {
			for (var i = 0; i < chooseDifferentPopupClose.length; i++) {
				chooseDifferentPopupClose[i].addEventListener('click', function(e) {
					e.preventDefault();
					chooseDifferentPopup.classList.remove('show-popup');
				})
			}
		}
	}
})();
